import { useEffect, useState } from 'react';
import { Avatar, Table, Group, Text, ActionIcon, Menu, rem, Skeleton } from '@mantine/core';
import {
    IconClockCog,
    IconDots,
    IconPasswordUser,
    IconTrash,
    IconUserEdit,
} from '@tabler/icons-react';
import { getAllUsers, deleteTimeOff } from '../data/dataApi';
import { useDisclosure } from '@mantine/hooks';
import { useUserState } from '../views/components/UserStateContext';
import ContentWrapper from '../views/components/ContentWrapper';
import EditUserModal from '../views/components/EditUserModal';
import { roles, userSettingsLinks, showSuccessNotification, showUnexpectedErrorNotification  } from '../views/helpers';
import ChangeUserPasswordModal from '../views/components/ChangeUserPasswordModal';
import ConfirmationModal from '../views/components/ConfirmationModal';
import { useNavigate } from 'react-router';

const UserAccounts = () => {
    const { userRole, users, setUsers, userId, resetAllStatistics } = useUserState();
    const [editUserModalOpen, {open: onEditUserModalOpen, close: onEditUserModalClose}] = useDisclosure(false);
    const [confirmationModalOpen, {open: onConfirmationModalOpen, close: onConfirmationModalClose}] = useDisclosure(false);
    const [changePasswordModalOpen, {open: onChangePasswordModalOpen, close: onChangePasswordModalClose}] = useDisclosure(false);
    const [deleteTimeOffDate, setDeleteTimeOffDate] = useState(false);
    const [settingsPage, setSettingsPage] = useState(null);
    const [modalUser, setModalUser] = useState(null);

    const navigate = useNavigate();

    const handleOpenModal = (e, user, callback, page) => {
        e.stopPropagation();
        page && setSettingsPage(page);
        setModalUser(user);
        callback();
    };

    const handleDeleteTimeOff = async () => {
        const response = await deleteTimeOff(modalUser.id, deleteTimeOffDate);

        if (response.success) {
            setUsers(prevState => {
                let newUsers = [...prevState];
                return newUsers.map(u => {
                    if (u.id === modalUser.id) {
                        return {
                            ...u,
                            pto: u.pto.filter(({date}) => date !== deleteTimeOffDate),
                        };
                    } else {
                        return u;
                    }
                });
            });
            setModalUser(prevState => {
                return {
                    ...prevState,
                    pto: prevState.pto.filter(({date}) => date !== deleteTimeOffDate),
                };
            });
            resetAllStatistics();
            showSuccessNotification("User time off settings deleted successfully.");
            onConfirmationModalClose();
        } else {
            showUnexpectedErrorNotification("There was an error saving the user time settings. Please try again.");
        }
    };

    useEffect(() => {
        (async () => {
            if(!users.length) {
                const response = await getAllUsers();

                if(response.success) {
                    setUsers(response.data.rows);
                }
            }
        })();
    }, []);

    useEffect(() => {
        !editUserModalOpen && setSettingsPage(null);
    }, [editUserModalOpen]);

    return (
        <ContentWrapper className="scrollable">
            <div id="user-accounts" className="wrapper">
                {
                    users.length ?
                        <>
                            <Text size="sm" className="tif-ls-50 text-uppercase tif-mb-15">
                                User Accounts
                            </Text>
                            <Table.ScrollContainer minWidth={800}>
                                <Table className="user-accounts-table" verticalSpacing="md" highlightOnHover>
                                    <Table.Tbody>
                                        {
                                            users.map(user => (
                                                <Table.Tr key={user.name} onClick={() => navigate(`/${user.username}`)}>
                                                    <Table.Td>
                                                        <Group gap="sm">
                                                            <Avatar size={40} src={user.avatar_url} radius={40} />
                                                            <div>
                                                                <Text fz="sm" fw={500}>
                                                                    {user.name}
                                                                </Text>
                                                                <Text c="dimmed" fz="xs">
                                                                    {roles[user.role]}
                                                                </Text>
                                                            </div>
                                                        </Group>
                                                    </Table.Td>
                                                    <Table.Td>
                                                        <Text fz="sm">{user.email}</Text>
                                                        <Text fz="xs" c="dimmed">
                                                            Email
                                                        </Text>
                                                    </Table.Td>
                                                    <Table.Td>
                                                        <Group gap={0} justify="flex-end">
                                                            {
                                                                userRole === "ops" ? 
                                                                    <ActionIcon variant="subtle" color="gray" onClick={e => handleOpenModal(e, user, onEditUserModalOpen, userSettingsLinks[2])}>
                                                                        <IconClockCog style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                                                                    </ActionIcon>
                                                                :
                                                                    <></>
                                                            }
                                                            <Menu
                                                                transitionProps={{ transition: 'pop' }}
                                                                withArrow
                                                                position="bottom-end"
                                                                withinPortal
                                                            >
                                                                {
                                                                    (userRole === "ops")  ?
                                                                        <>
                                                                            <Menu.Target>
                                                                                <ActionIcon variant="subtle" color="gray" onClick={e => e.stopPropagation()}>
                                                                                    <IconDots style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                                                                                </ActionIcon>
                                                                            </Menu.Target>
                                                                            <Menu.Dropdown>
                                                                                <Menu.Item
                                                                                    leftSection={
                                                                                        <IconUserEdit style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                                                                                    }
                                                                                    onClick={() => handleOpenModal(user, onEditUserModalOpen)}
                                                                                >
                                                                                    Edit user
                                                                                </Menu.Item>
                                                                                {
                                                                                    user.id === userId ?
                                                                                        <Menu.Item
                                                                                            leftSection={
                                                                                                <IconPasswordUser style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                                                                                            }
                                                                                            onClick={() => handleOpenModal(user, onChangePasswordModalOpen)}
                                                                                        >
                                                                                            Change password
                                                                                        </Menu.Item>
                                                                                    :
                                                                                        <></>
                                                                                }
                                                                                <Menu.Item
                                                                                    leftSection={<IconTrash style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
                                                                                    color="red"
                                                                                >
                                                                                    Delete Account
                                                                                </Menu.Item>
                                                                            </Menu.Dropdown>
                                                                        </>
                                                                    :
                                                                        <></>
                                                                }
                                                            </Menu>
                                                        </Group>
                                                    </Table.Td>
                                                </Table.Tr>
                                            ))
                                        }

                                    </Table.Tbody>
                                </Table>
                            </Table.ScrollContainer>
                        </>
                    :
                        <>
                            <Skeleton height={20} width={120} radius="md" className="tif-mb-15" />
                            <Skeleton height={805} radius="md" />
                        </>
                }
            </div>
            <EditUserModal
                opened={editUserModalOpen}
                close={onEditUserModalClose}
                user={ modalUser }
                onConfirmationModalOpen={onConfirmationModalOpen}
                onDeleteTimeOff={setDeleteTimeOffDate}
                onModalUserUpdate={setModalUser}
                settingsPage={settingsPage}
            />
            <ConfirmationModal opened={confirmationModalOpen} close={onConfirmationModalClose} message={`Are you sure you want to delete time off for ${ deleteTimeOffDate }?`} onAccept={handleDeleteTimeOff} />
            <ChangeUserPasswordModal opened={changePasswordModalOpen} close={onChangePasswordModalClose} user={ modalUser } />
        </ContentWrapper>
    );
};

export default UserAccounts;
