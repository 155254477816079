import ContentWrapper from '../views/components/ContentWrapper';
import { useEffect, useState } from 'react';
import { getUser, getUserActivityLog, getHoursLoggedPerDay, getTransient, setTransient } from '../data/dataApi';
import { handleUserStats, filteredOutsideMonth } from '../views/helpers';
import IndividualStatContent from '../views/components/IndividualStatContent';
import { Group, Text, Avatar, Skeleton, SimpleGrid, Paper, Badge } from '@mantine/core';
import { useUserState } from '../views/components/UserStateContext';
import UpdateStatus from '../views/components/UpdateStatus';
import { useNavigate, useParams } from 'react-router-dom';
import { IconBrandSlack, IconHeadphones } from '@tabler/icons-react';
import PTOCalendar from '../views/components/PTOCalendar';

const Profile = () => {
    const { refresh, setRefresh, users, statsDate, setCurrentMonthUsersTimeOff, setLoggedHoursPerDay } = useUserState([]);
    const [userStats, setUserStats] = useState([]);
    const [userActivityLog, setUserActivityLog] = useState();

    const user = userStats[0] || {};

    const { username } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const timeOffUser = users.filter(user => user.pto?.length && user.username === username).map(user => ({
                name: user.name,
                avatarUrl: user.avatar_url,
                pto: filteredOutsideMonth(user.pto, statsDate),
            })).filter(user => user.pto?.length);

            let hoursLoggedPerDayResponse = await getTransient(`hoursLoggedPerDayResponseUser_${username}`);
            
            if(!hoursLoggedPerDayResponse) {
                hoursLoggedPerDayResponse = await getHoursLoggedPerDay(statsDate, username);
                await setTransient(`hoursLoggedPerDayResponseUser_${username}`, hoursLoggedPerDayResponse, '30 minutes');
            }

            setCurrentMonthUsersTimeOff(timeOffUser);
            setLoggedHoursPerDay(hoursLoggedPerDayResponse.results);

            if(refresh || !user) {
                sessionStorage.setItem('elapsedTime', '0');
                setRefresh(false);

                const response = await getUser(username);
                if(response.success) {
                    handleUserStats(response.data.rows[0], setUserStats);
                } else {
                    navigate('/home');
                }

                let allEvents = [];

                const userActivityLogResponse = await getUserActivityLog(username);
                if(userActivityLogResponse.success) {
                    allEvents.push(...userActivityLogResponse.data.rows);
                }

                const groupEventsByHour = (events) => {
                    const groupedEvents = events.reduce((acc, event) => {
                        const startHour = new Date(event.start).getHours();
                        const hourLabel = `${startHour % 12 || 12}${startHour >= 12 ? 'PM' : 'AM'}`;
                
                        if (!acc[hourLabel]) {
                            acc[hourLabel] = [];
                        }
                
                        acc[hourLabel].push(event);
                        return acc;
                    }, {});
                
                    const sortedKeys = Object.keys(groupedEvents).sort((a, b) => {
                        const get24HourTime = (hourLabel) => {
                            const [hour, period] = hourLabel.match(/(\d+)(AM|PM)/).slice(1);
                            return (parseInt(hour) % 12) + (period === 'PM' ? 12 : 0);
                        };
                
                        return get24HourTime(a) - get24HourTime(b);
                    });
                
                    const sortedGroupedEvents = {};
                    sortedKeys.forEach(key => {
                        sortedGroupedEvents[key] = groupedEvents[key];
                    });
                
                    return sortedGroupedEvents;
                };
              
                setUserActivityLog(groupEventsByHour(allEvents));
            }
        })();
    }, [refresh, users]);

    const getDurationBetweenTimestamps = (startTimestamp, endTimestamp) => {
        const startDate = new Date(startTimestamp);
        const endDate = new Date(endTimestamp);
    
        const diffMs = endDate - startDate;
        const diffMinutes = Math.floor(diffMs / 60000);
        const hours = Math.floor(diffMinutes / 60);
        const minutes = diffMinutes % 60;
    
        let duration = '';
        if (hours > 0) {
            duration += `${hours}h `;
        }
        if (minutes > 0) {
            duration += `${minutes}m`;
        }
    
        return duration.trim();
    };

    const getFormattedTime = (timestamp) => {
        const date = new Date(timestamp);
        const hours = date.getHours() % 12 || 12;
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const period = date.getHours() >= 12 ? 'pm' : 'am';
        return `${hours}:${minutes}${period}`;
    };

    return (
        <ContentWrapper className="scrollable">
            <div className="wrapper">
                {
                    userStats.length ?
                        <>
                            <Group wrap="nowrap" mb="20" justify="space-between" align="center">
                                <PTOCalendar />
                                <UpdateStatus />
                            </Group>
                            <Group mb="30">
                                <Avatar src={user.avatar_url} radius="xl" size="50" />
                                <Text fz="lg">{user.name}</Text>
                            </Group>
                            <Group wrap="nowrap" align="flex-start">
                                <Paper withBorder p="md" radius="md" className="user-activity-log">
                                    {
                                        Object.keys(userActivityLog).length ?
                                            <ul className="list-unstyled">
                                                {
                                                    Object.keys(userActivityLog).map((hour, index) => 
                                                        <li key={index}>
                                                            <Text className="activity-log-time-label" fz="sm" color="dimmed" pb="5" mb="10">{hour}</Text>
                                                            <ul className="list-unstyled">
                                                                {
                                                                    userActivityLog[hour].map((event, index) =>
                                                                        event.type === 'slack_huddle' ?
                                                                            <li key={index}>
                                                                                <Group wrap="nowrap" align="flex-start" pl={30}>
                                                                                    <Group align="center">
                                                                                        <Text fz="xs" color="dimmed">{getFormattedTime(event.start)}</Text>
                                                                                        <Badge size="lg" color="#a1e942" variant="light" className="event-badge fw-normal flex-shrink-0" leftSection={<Avatar className="event-avatar" size="xs"><IconHeadphones color="#a1e942" size={14} /></Avatar>}>{ event.slack_channel }</Badge>
                                                                                    </Group>
                                                                                    <p className="tif-font-sm">You were on a huddle from {getFormattedTime(event.start)} to {getFormattedTime(event.end)} ({getDurationBetweenTimestamps(event.start, event.end)})</p>
                                                                                </Group>
                                                                            </li>
                                                                        :
                                                                            event.type === 'slack_message' ?
                                                                                <li key={index} className="tif-mb-15">
                                                                                    <Group wrap="nowrap" align="flex-start" pl={30}>
                                                                                        <Group align="center">
                                                                                            <Text fz="xs" color="dimmed">{getFormattedTime(event.start)}</Text>
                                                                                            <Badge size="lg" color="#ce1661" variant="light" className="event-badge fw-normal flex-shrink-0" leftSection={<Avatar className="event-avatar" size="xs"><IconBrandSlack color="#ce1661" size={14} /></Avatar>}>Slack</Badge>
                                                                                        </Group>
                                                                                        <p className="tif-font-sm tif-mb-0">#{ event.slack_channel }</p>
                                                                                    </Group>
                                                                                </li>
                                                                            :
                                                                                null
                                                                    )
                                                                }
                                                            </ul>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        :
                                            <>No activity recorded.</>
                                    }
                                </Paper>
                                <IndividualStatContent user={user} gridCols={2} />
                            </Group>
                        </>
                    :
                        <>
                            <Group mb={30}>
                                <Skeleton height={50} width={50} circle />
                                <Skeleton height={30} width={100} radius="md" />
                                <Skeleton height={25} ml="auto" width={150} radius="md" />
                            </Group>
                            <Skeleton height={30} width={100} radius="md" className="tif-mb-10" />
                            <Skeleton height={166} radius="md" mb={30} />
                            <Skeleton height={30} width={100} radius="md" className="tif-mb-10" />
                            <Skeleton height={166} radius="md" mb={30} />
                            <SimpleGrid cols={{ base: 1, md: 4 }} className="tif-mb-40">
                                <Skeleton height={400} radius="md" />
                                <Skeleton height={400} radius="md" />
                                <Skeleton height={400} radius="md" />
                                <Skeleton height={400} radius="md" />
                                <Skeleton height={400} radius="md" />
                                <Skeleton height={400} radius="md" />
                                <Skeleton height={400} radius="md" />
                            </SimpleGrid>
                        </>
                }
            </div>
        </ContentWrapper>
    );
};

export default Profile;