import React, { createContext, useState, useContext, useEffect } from 'react';
import { checkUser, getAllUsers, getDefaultFieldGroups, getUserAlerts } from '../../data/dataApi';
import { useDisclosure } from '@mantine/hooks';
import { useParams } from 'react-router';

export const UserStateContext = createContext();

export const UserStateProvider = ({ children }) => {
  let excludeAccountsStatus;

  if (localStorage.getItem("exclude_accounts") === "true") {
    excludeAccountsStatus = "true";
  } else if (localStorage.getItem('exclude_accounts') === "false") {
    excludeAccountsStatus = "false";
  } else {
    excludeAccountsStatus = "null";
  }

  // User
  const [userRole, setUserRole] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [hasTempoTokens, setHasTempoTokens] = useState(null);
  const [jiraAccountId, setJiraAccountId] = useState(null);
  const [hasJiraApiToken, setHasJiraApiToken] = useState(null);
  const [excludeAccounts, setExcludeAccounts] = useState(excludeAccountsStatus);
  const [refresh, setRefresh] = useState(sessionStorage.getItem('elapsedTime') || 300);
  const [userStats, setUserStats] = useState([]);
  const [globalEditingMode, setGlobalEditingMode] = useState(null);
  const initialStatsDate = new Date();
  initialStatsDate.setHours(0, 0, 0, 0);
  const [statsDate, setStatsDate] = useState(initialStatsDate);
  const [alerts, setAlerts] = useState([]);
  const [feedbucketEnabled, setFeedbucketEnabled] = useState(localStorage.getItem("feedbucket_enabled") === "1");
  const [issueWatchModalOpen, { open: openIssueWatchWatchModal, close: closeIssueWatchModal }] = useDisclosure(false);
  const [selectedWatchIssue, setSelectedWatchIssue] = useState(null);
  const [watchedIssues, setWatchedIssues] = useState([]);

  // Team Statistics
  const [statistics, setStatistics] = useState(null);
  const [currentMonthUsersTimeOff, setCurrentMonthUsersTimeOff] = useState([]);
  const [loggedHoursPerDay, setLoggedHoursPerDay] = useState([]);

  // All Users
  const [users, setUsers] = useState([]);

  // Individual Statistics
  const [individualStatistics, setIndividualStatistics] = useState([]);

  // Clients
  const [clients, setClients] = useState(null);
  const [clientDetails, setClientDetails] = useState();

  // Field Groups
  const [globalDefaultFieldGroups, setGlobalDefaultFieldGroups] = useState([]);
  const [projectDefaultFieldGroups, setProjectDefaultFieldGroups] = useState([]);
  const [targetsDefaultFieldGroups, setTargetsDefaultFieldGroups] = useState([]);
  const [maintenanceDefaultFieldGroups, setMaintenanceDefaultFieldGroups] = useState([]);
  const [adHocDefaultFieldGroups, setAdHocDefaultFieldGroups] = useState([]);

  const { username } = useParams();

  const resetAllStatistics = () => {
    setStatistics(null);
    setIndividualStatistics([]);
  };

  useEffect(() => {
    (async () => {
      const userResponse = await checkUser();

      setUserId(userResponse.id);
      setUserRole(userResponse.role);
      setHasTempoTokens((!!userResponse.tempo_access_tokens && userResponse.tempo_access_tokens !== "NULL"));
      setJiraAccountId(userResponse.jira_account_id);
      setHasJiraApiToken((!!userResponse.api_key && userResponse.api_key !== "NULL"));

      const usersResponse = await getAllUsers();

      if (usersResponse.success) {
        const users = usersResponse.data.rows;

        setUsers(users);
      }
    })();
  }, [loggedIn]);

  useEffect(() => {
    localStorage.setItem("exclude_accounts", excludeAccounts);
  }, [excludeAccounts]);

  useEffect(() => {
    const unloadCallback = (event) => {
      if (globalEditingMode) {
        event.preventDefault();
        event.returnValue = "";
        return "";
      }
    };

    window.addEventListener("beforeunload", unloadCallback);

    return () => {
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, [globalEditingMode]);

  useEffect(() => {
    (async () => {
        const response = await getDefaultFieldGroups();

        if(response.success) {
            const updatedDefaultFieldGroups = response.data.rows.map(group => {
              group.fields = group.fields.map(field => {
                  field.originalName = field.name;
                  return field;
              });
              return group;
          });

          setGlobalDefaultFieldGroups(updatedDefaultFieldGroups.filter(group => group.client_type === null));
          setProjectDefaultFieldGroups(updatedDefaultFieldGroups.filter(group => group.client_type === "Project"));
          setTargetsDefaultFieldGroups(updatedDefaultFieldGroups.filter(group => group.client_type === "Targets"));
          setMaintenanceDefaultFieldGroups(updatedDefaultFieldGroups.filter(group => group.client_type === "Maintenance"));
          setAdHocDefaultFieldGroups(updatedDefaultFieldGroups.filter(group => group.client_type === "Ad Hoc"));
        }
    })();

    (async () => {
      const response = await getUserAlerts();

      if(response.success) {
          setAlerts(response.data.rows);
      }
    })();
  }, []);

  useEffect(() => {
    const checkFeedbucketApp = () => {
      const feedbucketApp = document.querySelector('feedbucket-app');

      if (feedbucketApp) {
        if (feedbucketEnabled) {
          feedbucketApp.style.display = 'block';
          localStorage.setItem("feedbucket_enabled", 1);
        } else {
          feedbucketApp.style.display = 'none';
          localStorage.removeItem("feedbucket_enabled");
        }
      } else {
        setTimeout(checkFeedbucketApp, 100);
      }
    };

    checkFeedbucketApp();
  }, [feedbucketEnabled]);

  const handleOpenIssueWatchWatchModal = issueId => {
    setSelectedWatchIssue(issueId);
    openIssueWatchWatchModal();
  };

  return (
    <UserStateContext.Provider value={{
      userId,
      setUserId,
      userRole,
      setUserRole,
      setLoggedIn,
      hasTempoTokens,
      setHasTempoTokens,
      jiraAccountId,
      setJiraAccountId,
      hasJiraApiToken,
      setHasJiraApiToken,
      statistics,
      setStatistics,
      users,
      setUsers,
      individualStatistics,
      setIndividualStatistics,
      excludeAccounts,
      setExcludeAccounts,
      resetAllStatistics,
      currentMonthUsersTimeOff,
      setCurrentMonthUsersTimeOff,
      refresh,
      setRefresh,
      userStats,
      setUserStats,
      clients,
      setClients,
      globalEditingMode,
      setGlobalEditingMode,
      statsDate,
      setStatsDate,
      globalDefaultFieldGroups,
      setGlobalDefaultFieldGroups,
      clientDetails,
      setClientDetails,
      projectDefaultFieldGroups,
      setProjectDefaultFieldGroups,
      targetsDefaultFieldGroups,
      setTargetsDefaultFieldGroups,
      maintenanceDefaultFieldGroups,
      setMaintenanceDefaultFieldGroups,
      adHocDefaultFieldGroups,
      setAdHocDefaultFieldGroups,
      alerts,
      setAlerts,
      setLoggedHoursPerDay,
      loggedHoursPerDay,
      feedbucketEnabled,
      setFeedbucketEnabled,
      issueWatchModalOpen,
      openIssueWatchWatchModal: handleOpenIssueWatchWatchModal,
      closeIssueWatchModal,
      selectedWatchIssue,
      watchedIssues,
      setWatchedIssues
    }}>
      {(!loggedIn || userId) && children}
    </UserStateContext.Provider>
  );
};

export const useUserState = () => {
  const context = useContext(UserStateContext);
  if (!context) {
    throw new Error('useUserState must be used within a UserStateProvider');
  }
  return context;
};