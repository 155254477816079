import { Group } from "@mantine/core";
import PTOCalendarDay from "./PTOCalendarDay";
import { useUserState } from "./UserStateContext";

const PTOCalendar = ({ className }) => {
    const { currentMonthUsersTimeOff, statsDate, loggedHoursPerDay } = useUserState();

    const now = statsDate;
    const year = now.getFullYear();
    const month = now.getMonth();

    const daysInMonth = new Date(year, month + 1, 0).getDate();

    const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);

    console.log(currentMonthUsersTimeOff, loggedHoursPerDay)

    return (
        <Group gap="5px" className={`pto-calendar ${className}`} align="flex-start">
            {daysArray.map((day) =>
                <PTOCalendarDay day={day} key={day} data={{currentMonthUsersTimeOff, loggedHoursPerDay}} />
            )}
        </Group>
    );
};

export default PTOCalendar;